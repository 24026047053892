import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import AppFooter from './components/shared/AppFooter';
import AppHeader from './components/shared/AppHeader';
import './css/App.css';
import UseScrollToTop from './hooks/useScrollToTop';

const About = lazy(() => import('./pages/AboutMe'));
const Publicaciones = lazy(() => import('./pages/proyectos/Publicaciones'));
const Contact = lazy(() => import('./pages/Contact.jsx'));
const Home = lazy(() => import('./pages/Home'));
const Projects = lazy(() => import('./pages/Projects'));
const ProjectSingle01 = lazy(() => import('./pages/proyectos/ProjectSingle01'));
const ProjectSingle02 = lazy(() => import('./pages/proyectos/ProjectSingle02'));
const ProjectSingle03 = lazy(() => import('./pages/proyectos/ProjectSingle03'));
const ProjectSingle04 = lazy(() => import('./pages/proyectos/ProjectSingle04'));
const ProjectSingle05 = lazy(() => import('./pages/proyectos/ProjectSingle05'));
const ProjectSingle06 = lazy(() => import('./pages/proyectos/ProjectSingle06'));

function App() {
	return (
		<AnimatePresence>
			<div className=" bg-secondary-light dark:bg-primary-dark transition duration-300">
				<Router>
					<ScrollToTop />
					<AppHeader />
					<Suspense fallback={""}>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="trayectoria" element={<Projects />} />
							<Route
								path="trayectoria/berazategui_2050"
								element={<ProjectSingle01 />}
							/>
							<Route
								path="trayectoria/bice"
								element={<ProjectSingle02 />}
							/>

                            <Route
								path="trayectoria/diputado_provincial"
								element={<ProjectSingle03 />}
							/>
 
                           <Route
								path="trayectoria/fundacion_pro_buenos_aires"
								element={<ProjectSingle04 />}
							/>    
                           <Route
								path="trayectoria/sembrando_patria"
								element={<ProjectSingle05 />}
							/> 


                           <Route
								path="trayectoria/patrimonio"
								element={<ProjectSingle06 />}
							/> 

							<Route path="about" element={<About />} />
							<Route path="publicaciones" element={<Publicaciones />} />
							<Route path="contact" element={<Contact />} />
						</Routes>
					</Suspense>
					<AppFooter />
				</Router>
				<UseScrollToTop />
			</div>
		</AnimatePresence>
	);
}

export default App;
