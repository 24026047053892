import { motion } from 'framer-motion';
import { FiX } from 'react-icons/fi';
import Button from './reusable/Button';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';

const selectOptions = [
	'Elije un motivo',
	'Camara de Diputados Pcia. Bs.As.',
	'Consultoria Financiera',
	'ARCA 2050',
	'Berazategui 2050',
	'Fundación Pro Buenos Aires'
];

const HireMeModal = ({ onClose, onRequest }) => {

	const [updatedName, setUpdatedName] = useState("");

	const { register, handleSubmit, formState: { errors } } = useForm();
	
	const [formData, setFormData] = useState({
		mail:'',
		nombre:'',
		mensaje:'',
		motivo:''
});

	const envioMail = (data) => {
        data.nombre = formData.nombre;
        data.mail = formData.mail;
        data.mensaje = formData.mensaje;
		data.motivo = formData.motivo;

        axios.post('https://worldgate.link:4000/apiEnvioMailHugo/datos', data)
            .then(res => {
                res.status === 200 ? toast.success("Sector Booked") : toast.error("Error Occured, Please Resubmit.")
				setFormData({
					nombre: '',
					mail: '',
					mensaje:'',
					motivo:''
				 });
				cerrar();
            })
            .catch(error => console.log(error.message));
    }


	const handleValidInput = (e) => {
        setUpdatedName(e.target.value);
		console.log('Este es el nombre: '+updatedName)
    }


	const cerrar = (e) => {
        onClose();
    }

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className="font-general-medium fixed inset-0 z-30 transition-all duration-500"
		>
			{/* Modal Backdrop */}
			<div className="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20"></div>

			{/* Modal Content */}
			<main className="flex flex-col items-center justify-center h-full w-full">
				<div className="modal-wrapper flex items-center z-30">
					<div className="modal max-w-md mx-5 xl:max-w-xl lg:max-w-xl md:max-w-xl bg-secondary-light dark:bg-primary-dark max-h-screen shadow-lg flex-row rounded-lg relative">
						<div className="modal-header flex justify-between gap-10 p-5 border-b border-ternary-light dark:border-ternary-dark">
							<h5 className=" text-primary-dark dark:text-primary-light text-xl">
								¿Queires que conversemos?
							</h5>
							<button
								onClick={cerrar}
								className="px-4 font-bold text-primary-dark dark:text-primary-light"
							>
								<FiX className="text-3xl" />
							</button>
						</div>
						<div className="modal-body p-5 w-full h-full">
			<form  action="" className=' w-full mx-auto relative mt-4 md:mt-8'>
                

				<div className="">
									<input
										className="w-full px-5 py-2 border dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
										required { ...register("nombre") } 
										id="name"
										name="name"
										type="text"
										placeholder="Name"
										aria-label="Name"
										onChange={(e) => {
											setFormData({
											  ...formData,
											  nombre: e.target.value,
											});
											///setError(false)
											}}
											 value={formData.nombre}
									/>
								</div>
								<div className="mt-6">
									<input
										className="w-full px-5 py-2 border dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
										id="email"
										name="email"
										type="text"
										required=""
										placeholder="Email"
										aria-label="Email"
										onChange={(e) => {
											setFormData({
										   ...formData,
										   mail: e.target.value,
										  });
										  ///setErrorMail(false)
										 }}
										 value={formData.mail}
									/>
								</div>
								<div className="mt-6">
									<select
										className="w-full px-5 py-2 border dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
										id="subject"
										name="subject"
										placeholder="Motivo"
										type="text"
										required=""
										aria-label="Project Category"
										onChange={(e) => {
											setFormData({
										   ...formData,
										   motivo: e.target.value,
										  });
										  ///setErrorMail(false)
										 }}
										 value={formData.motivo}
									>
										{selectOptions.map((option) => (
											<option
												className="text-normal sm:text-md"
												key={option}
											>
												{option}
											</option>
										))}
									</select>
								</div>

								<div className="mt-6">
									<textarea
										className="w-full px-5 py-2 border dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
										id="message"
										name="message"
										cols="14"
										rows="6"
										aria-label="Details"
										placeholder="Project description"
										onChange={(e) => {
											setFormData({
										   ...formData,
										   mensaje: e.target.value,
										  });
										  ///setErrorMail(false)
										 }}
										 value={formData.mensaje}
									></textarea>
								</div>




                
                <br />
                <div className=''>
                    <button
                        id='submit-btn'
                        type='submit'
                        onClick={ handleSubmit(envioMail)}
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                        Enviar mensaje
                    </button>
                </div>
            </form >
						</div>
						<div className="modal-footer mt-2 sm:mt-0 py-5 px-8 border0-t text-right">
							<span
								onClick={cerrar}
								type="button"
								className="px-4
									sm:px-6
									py-2 bg-gray-600 text-primary-light hover:bg-ternary-dark dark:bg-gray-200 dark:text-secondary-dark dark:hover:bg-primary-light
									rounded-md
									focus:ring-1 focus:ring-indigo-900 duration-500"
								aria-label="Close Modal"
							>
								<Button title="Cancelar" />
							</span>
						</div>
					</div>
				</div>
			</main>
		</motion.div>
	);
};

export default HireMeModal;
